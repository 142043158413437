import React, { useState } from 'react';
import CartOrderSummary from "./cart-order-summary";
import { toCurrencyFormat } from "../helpers/currency-format-helper";
import useCost from "../hooks/use-cost";

export default function CartSummary() {
    const { subTotal } = useCost()
    const [showSummary, setShowSummary] = useState(() => false);
    return (
        <div>
            <div className="p-2 mb-2 text-white bg-pas-peach flex justify-between items-center rounded-sm">
                <div className='flex' onClick={() => setShowSummary(p => !p)}>
                    <span className='font-ovo text-sm tracking-wide pr-1'>{!showSummary ? 'Show' : 'Hide'}  Order Summary </span>
                    {
                        !showSummary ? <ArrowDown /> : <ArrowUp />
                    }
                </div>
                {<span className='font-ovo tracking-wider'>{toCurrencyFormat(subTotal)}</span>}
            </div>
            <div className={`${showSummary ? 'block': 'hidden'} transition duration-1000 ease-out hidden:ease-in`}>
                <CartOrderSummary />
            </div>
            {/* {
                showSummary ? <CartOrderSummary /> : <></>
            } */}
        </div>
    )
}

function ArrowDown() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mt-0.5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
    )
}

function ArrowUp() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mt-0.5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clipRule="evenodd" />
        </svg>
    )
}
