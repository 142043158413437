import React, { useState, useEffect } from "react"
import Seo from "../components/seo"
import BrandHeading from "../components/brand-heading";
import {
    FormInputText, FormInputEmail, FormInputTel, FormButton, FormLinkButton
} from '../components/form-elements';
import { emailValidation, requiredValidation } from "../helpers/form-validation-helper";
import { useCheckoutActionContext, useCheckoutContext } from "../components/checkout-context";
import { navigate } from "gatsby";
import CartSummary from "../components/cart-summary";
import CheckoutSteps from "../components/checkout-steps";


export default function CheckoutCustomer() {
    const { upsertCustomerInfo } = useCheckoutActionContext();
    const { customer } = useCheckoutContext();
    const [name, setName] = useState(() => customer.name ? customer.name : "");
    const nameValidations = [(x) => requiredValidation(x, "Name")];

    const [email, setEmail] = useState(() => customer.email ? customer.email : "");
    const emailValidations = [(x) => requiredValidation(x, "Email"), emailValidation];

    const [phone, setPhone] = useState(() => customer.phone ? customer.phone : "");
    const phoneValidations = [(x) => requiredValidation(x, "Phone")];

    const [formSubmitDisabled, setFormSubmitDisabled] = useState(() => true);
    const [formProcessing, setFormProcessing] = useState(() => false);

    const form = {
        name, setName, nameValidations,
        email, setEmail, emailValidations,
        phone, setPhone, phoneValidations,
        formSubmitDisabled,
        formProcessing
    }

    const formSubmit = (e) => {
        e.preventDefault();
        setFormProcessing(true);
        upsertCustomerInfo({ name, email, phone });
        navigate('/checkout-fulfillment/');
        setFormProcessing(false);
    };

    useEffect(
        () => {
            if (nameValidations.find(f => f(name))) setFormSubmitDisabled(true);
            else if (emailValidations.find(f => f(email))) setFormSubmitDisabled(true);
            else if (phoneValidations.find(f => f(phone))) setFormSubmitDisabled(true);
            else setFormSubmitDisabled(false);

        },
        [name, email, phone]
    );

    return (
        <>
            <Seo title="Checkout Customer" />
            <BrandHeading title="checkout" />
            <section>
                <CartSummary />
                <CheckoutSteps step="customer" />
                <Form form={form} onSubmit={formSubmit} />
            </section>
        </>
    )
}

function Form({ form, onSubmit }) {
    return (
        <div className="grid grid-cols-1 gap-6 max-w-2xl mx-auto">
            <FormInputText label={"NAME *"} value={form.name} onChange={form.setName} validations={form.nameValidations} />
            <FormInputEmail label={"EMAIL *"} value={form.email} onChange={form.setEmail} validations={form.emailValidations} />
            <FormInputTel label={"PHONE *"} value={form.phone} onChange={form.setPhone} validations={form.phoneValidations} />

            <hr />
            <div className="max-w-xs w-full mx-auto my-8">
                <FormButton
                    disabled={form.formSubmitDisabled || form.formProcessing}
                    className={"w-full mb-4"}
                    label={"CONTINUE"}
                    onClick={e => { onSubmit(e) }} />
                <FormButton className="w-full mb-4" label={"BACK"} onClick={() => navigate(`/cart/`)} isSecondary={true} />
                <p className="font-gotu italic text-center text-[0.65rem] tracking-wider text-red-500">
                    {form.formSubmitDisabled ? `Please fill out the required (*) fields to enable the SUBMIT button` : ""}
                </p>
            </div>
        </div>
    )
}