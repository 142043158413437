import React from 'react'
import { Link } from "gatsby"
export default function CheckoutSteps({ step }) {
    const getClassName = (x) => step == x ? 'border-b border-b-pas-peach' : '';

    return (
        <div className='
            flex justify-between
            py-3 mb-8 px-1 max-w-2xl mx-auto
            font-ovo text-xs sm:text-sm tracking-wide md:tracking-wider text-gray-800
        '>
            <span className={getClassName('customer')}>
                <LinkOrLabel step={step} code='customer' label='YOUR DETAILS' to='/checkout-customer' /></span>
            <span className={getClassName('fulfillment')}>
                <LinkOrLabel step={step} code='fulfillment' label='COLLECTION' to='/checkout-fulfillment' /></span>
            <span className={getClassName('notes')}>
                <LinkOrLabel step={step} code='notes' label='NOTES' to='/checkout-notes' /></span>
            <span className={getClassName('review')}>
                <LinkOrLabel step={step} code='review' label='REVIEW' to='/checkout-review' /></span>
        </div>
    )
}

function LinkOrLabel({ step, code, label, to }) {
    let useLink = false;
    
    if (step === 'fulfillment' && (code === 'customer')) useLink = true;
    else if (step === 'notes' && (code === 'customer' || code === 'fulfillment')) useLink = true;
    else if (step === 'review' && (code === 'customer' || code === 'fulfillment' || code === 'notes')) useLink = true;
    
    const element = useLink ?
        <Link to={to}>
            {label}
        </Link>
        : <span>{label}</span>;

    return (
        <>
            {element}
        </>
    )
}
