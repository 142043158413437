import React from "react"
import { useCartItemsContext } from "./cart-context";
import { toCurrencyFormat } from "../helpers/currency-format-helper";
import CakeItemDetail from "./cake-item-detail";
import useCost from "../hooks/use-cost";

export default function CartOrderSummary() {
    const cartItems = useCartItemsContext()
    const { getCakeAmount } = useCost()
    return (
        <div>
            {
                cartItems.map(cake =>
                    <div
                        key={cake.key}
                        className="flex flew-row justify-between">
                        <CakeItemDetail cake={cake} hideActions={true} />
                        <div className="py-2 pr-2 pt-5 font-ovo text-base md:text-lg tracking-wider text-gray-800">{toCurrencyFormat(getCakeAmount(cake))}</div>
                    </div>)
            }
            <hr className="my-4" />
        </div>
    )
}


